function executeAction(id, params, onSuccess, onError) {
  window.dispatchEvent(new CustomEvent('onExecuteAction', {detail: {id: id, params: params, onSuccess: onSuccess, onError: onError}}));
}

function openModal(path, id, params, onClose) {
  window.dispatchEvent(new CustomEvent('onOpenModal', {detail: {path: path, id: id, params: params, onClose: onClose}}));
}

function refreshCard(id, params) {
  window.dispatchEvent(new CustomEvent('onRefreshCard', {detail: {id: id, params: params}}));
}

function snackbar(type, message, onDismiss) {
  window.dispatchEvent(new CustomEvent('onSnackBar', {detail: {type: type, message: message, onDismiss: onDismiss}}));
}

function openInSidenav(url, windowPath) {
  window.dispatchEvent(new CustomEvent('onOpenInSideNav', {detail: {url: url, windowPath: windowPath}}));
}

function reload(params) {
  const urlParams = getAllUrlParams(window.location.href);
  if (params) {
    Object.keys(params).forEach(function (key) {
      urlParams[key] =  params[key];
    });

    const paramSeparator = window.location.href.indexOf("?");

    let separator = '?';
    let queryString = ''
    Object.keys(urlParams).forEach(function (key) {
      queryString = queryString + separator + key + '=' + encodeURI(urlParams[key])
      separator = '&';
    });
    window.location.href = window.location.href.substring(0, (paramSeparator >= 0 ? paramSeparator : window.location.href.length)) + queryString;
  }
  window.location.reload();
}

function fillCombo(name, options) {
  const combo = document.getElementById(name);

  combo.innerHTML = "";
  options.forEach(option => {
    var newOption = document.createElement("option");
    newOption.value = option.value;
    newOption.text = option.text;
    newOption.selected = option.selected || false;
    combo.add(newOption);
  });
}
